.home{
    position: relative;
}
.btnhomenav{
    display:inline-block; 
    padding:0.3em 1.2em; 
    margin:0 0.1em 0.1em 0; 
    border:0.16em solid rgba(255,255,255,0); 
    border-radius:2em; box-sizing: border-box; 
    text-decoration:none; font-family:'Roboto',sans-serif; 
    font-weight:500; 
    color:#FFFFFF; 
    text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35); 
    text-align:center; 
    transition: all 0.2s;
}
.btnhomenav:hover{
    border-color: rgba(255,255,255,1);
}
@media all and (max-width:30em){ 
    .btnhomenav{ display:block; margin:0.8em auto; } 
}
.aboutmeImg{
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.content{
    /* padding-top: 10px; */
    margin-top: 50px;
    padding-right: 20px;
    padding-left: 0px;
    font-style: italic;
    text-align: justify;
}


@media screen and (max-width: 600px){
    .aboutmeImg{
        width: 100%;
        float: left;
    }
    .content{
        background-color: black;
        padding: 10px;
        text-align: left;
    }
}

@media screen and (min-width: 600px){
    .aboutmeImg{
        width: 100%;
        float: left;
    }
}
.footer{
    width: 100%;
    height: auto;
    background-color: black;
    display: block;
}
.avatar{
    width: 100%;
}
.copyright{
    padding-top:20px;
    padding-bottom: 10px;
    color: white; 
    text-align: center;
}
.title{
    padding-left: 10px;
}
.linkto{
    padding-left: 10px;
    text-align: center;
    color: white;
}
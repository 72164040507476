.inputelment{
    font-size: 19px;
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    background-color: white;
    padding: 6px 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: block;
    box-sizing: border-box;
}
.Input{
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    padding-top: 10px;
    margin-bottom: 10px;
}

.Label{
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}
.InputElement:disabled{
    columns: rgb(18, 2, 245);
}
.InputElement:focus{
    outline: none;
    background-color: #ccc;
}

.Invalid{
    border: 1px solid red;
    background-color: rgb(245, 170, 161);
}

.Bold{
    font-weight: bold;
    color: green;
}

@media (min-width: 600px){
    .Input{
        width: 410px;
    }
}

@media screen and (max-width: 600px){
    .Input{
        width: 98%;
    }
}
.scheduleinfo{
    font-size: medium;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.AccordionButton{   
    position: relative;
    background-color:rgb(40, 78, 9);
    color: white;
    font-size: large;
    width: 100%;
    height: 40px;
}
.dayclass {
    color: red;
    font-weight: bolder;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .AccordionButton{
        position: relative;
        background-color:rgb(40, 78, 9);
        color: white;
        font-size: large;
        width: 382px;
        height: 40px;
    }
}
.tag {
    margin-top: 50px;
    padding-right: 10px;
    padding-left: 0px;
}
.tag a{
    display: block;
    padding-left: 0px;
}
.content{
    color: green;
}
.content :hover{
    cursor: pointer;
}
@media screen and (max-width: 600px){
    .tag{
        background-color: black; 
        padding: 10px;
        text-align: left;
    }
}
.post_details{
    text-align: left;
}
.row{
    margin: 30px 0px 10px;
}
.col{
    padding: 0px;
}
.container{
    padding: 0px;
}
.postinfo{
    font-style: italic;
    color: #c57575;
    float: right;
}
.postinfoicon{
    height: 20px;
    width: 20px;
}
.postinfoicon :hover{
    cursor: hand;
}

.divbody img {
    max-width: 100%;
}
.divbody span{
    word-wrap: break-word;
}

.spinner_custom{
    position: fixed;
    top: 30%;
    left: 50%;
}
.spinner_background{
    opacity: 50%;
    width: 100vw;
    height: 100vw;
}
.header{
    padding-top: 0px;
    display: inline-flex;
    width: 100%;
    height: auto;
    background-color:rgb(40, 78, 9);
    border-bottom: 0.1em solid wheat;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

ul li{
    display: inline;
    text-align: center;
}
ul li :hover{
    background-color: rgb(43, 83, 68);
    color: white; 
    text-decoration: none;
}
ul a{
    padding: 10px;
    color: white;
}
.colornav{
    background-color:rgb(40, 78, 9);
}
.colornavtext{
    padding-left: 10px;
    color: white;
}

.colornavtext:hover{
    color: yellow;
    text-decoration: none;
}


@media screen and (max-width: 575px){
    .header{
        display: inline;    
        top: 0;
        left: 0;
        z-index: 100;
    }
    ul li {
        display: inline;
        text-align: left;
    }
    ul a{
        padding: 5px;
        color: white;
    }
}

.App {
  font-family: 'Open Sans','Trebuchet MS', sans-serif; 
  font-size: 16px;
  /* background-color: whitesmoke; */
  background-color: black;
  color: white;
  height: auto;
}

.button {
  background-color:rgb(40, 78, 9);
  border: solid 1px grey;
  color: white;
  padding: 0px 10px;
  margin-left: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.button:hover {
  background-color :#afeb0b;; /* Green */
  border: solid 1px red;
  color: white;
  padding: 0px 10px;
  margin-left: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.button:disabled {
  background-color:grey; /* Green */
  border: solid 1px grey;
  color: white;
  padding: 0px 10px;
  margin-left: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}
.button1 {
  background-color:rgb(40, 78, 9);
  color: white;
  border: none;
  padding: 0px 5px;
  margin-left: 0px;
  margin-bottom: 2px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 14px;
}
.button1:hover {
  background-color: #4CAF50;
  border: solid 1px rgb(17, 16, 16);
  color: white;
  padding: 0px 5px;
  margin-left: 0px;
  margin-bottom: 2px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

table, td, th {
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .App{
    height: auto;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  
}

/* For landscape screen display*/
@media only screen and (orientation: landscape) {
  body {
    background-color: lightblue;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {    
    margin-left: none;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 10px;
}
.post_title{
    font-weight: bold;
}
.post_desc{
    padding-left: 10px;
}
.post_img_icon{
    padding: none;
    border-radius: 5px;
    width: 100%;
    height: 150px;
}
.postinfo{
    font-style: italic;
    float: right;
}
.postinfoicon{
    height: 20px;
    width: 20px;
}
.postinfoicon :hover{
    cursor: hand; 
}
.spinner_custom{
    position: fixed;
    top: 30%;
    left: 50%;
}
.spinner_background{
    opacity: 50%;
    width: 100vw;
    height: 100vw;
}

@media screen and (max-width: 600px){
    .post_img_icon{
        padding: none;
        border-radius: 5px;
        width: 100%;
        height: auto;
    }
}
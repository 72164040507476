.auth{
    display: block;
    margin: 20px auto;
    width: auto;
    text-align: center;
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10px;
    box-sizing: border-box;
}
.err{
    color: red;
    font-size: larger;
}

.button{
    width: 50px;
    height: auto;
    border: 1px solid #eee;
    margin-left: 5px;
}

@media (min-width: 600px){
    .auth{
        width: 430px;
    }
}
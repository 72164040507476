.cusinfo{
    border: 1px solid green;
    text-align: left;
    padding-left: 20px;
}
.weekdays{
    display: inline;
}

.customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .customers td, .customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  /* .customers tr:nth-child(even){background-color: #f2f2f2;} */
  
  .customers tr:hover {background-color:grey;}
  
  .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
  }


  .button1 {
    display:inline-block; 
    padding:0.3em 1.2em; 
    margin:0 0.1em 0.1em 0; 
    border:0.16em solid rgba(121, 91, 91, 0); 
    border-radius:1em; box-sizing: border-box; 
    text-decoration:none; font-family:'Roboto',sans-serif; 
    font-weight:500; 
    color:#251f1f; 
    text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35); 
    text-align:center; 
    transition: all 0.2s;
  }
  .button1:hover {
    border-color: rgb(104, 40, 40);
  }

  @media all and (max-width:30em){ 
    .button1{ display:block; margin:0.8em auto; } 
}
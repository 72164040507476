.postEntry{
    display: block;
}

.submit{
    font-size: larger;
    margin: 10px 5px 10px;
    border: 1px solid grey;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border-radius: 2px;
}
.err{
    color: red;
    font-size: larger;
}